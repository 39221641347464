<div [ngClass]="{'fileUploadContainerBorder': hasBorder}">
    <section class="header-container" *ngIf="enabledSelectFileType">
        <div class="category-container">
            <p-dropdown
                optionLabel="name"
                [(ngModel)]="selectedCategory"
                [options]="categories" 
                [ngModelOptions]="{standalone: true}"
                [showClear]="true"
                (onChange)="changeFileType()"
                placeholder="Type of deliverable"
                >
            </p-dropdown>
        </div>

        <div *ngIf="showDeleteButton" class="delete-container" (click)="removeResourceForParent()">
            <i class="pi pi-trash icon"></i>
        </div>
    </section>

    <div class="container" [ngClass]="{'bg-white': backgroundWhite}">
        <div>
            <mdk-card-info *ngIf="showTips" [title]="tipsTitle" [fullWidth]="true" [messages]="selectedTips"></mdk-card-info>
        </div>

        <div class="fileUploadContainer">
            <div class="inputContainer" *ngIf="enabledDescription">
                <span class="p-float-label width100">
                    <input  type="text" pInputText #title id="titleInput" [(ngModel)]="description"
                        class="width100 input-element"
                        (blur)="emitDescription()"
                        (focus)="labelTitle.classList.add('label-focus')"
                        (focusout)="labelTitle.classList.remove('label-focus')">
                    <label #labelTitle class="label-text" for="titleInput"
                        >Description*
                    </label>
                </span>
            </div>
            <div *ngIf="selectedCategory.name === 'Link'" class="inputContainer">
                <span class="p-float-label width100">
                    <input  type="text" pInputText #title id="link" [(ngModel)]="link"
                        [ngClass]="{'invalid-input': !isLinkValid}"
                        class="width100 input-element"
                        (blur)="emitDescription()" 
                        (focus)="labelTitle.classList.add('label-focus')"
                        (focusout)="labelTitle.classList.remove('label-focus')">
                    <label #labelTitle class="label-text" for="link"
                        >Link*
                    </label>
                    <span *ngIf="!isLinkValid"
                    class="label-error label-message">It must be a valid URL. Example https://www.modakmakers.com/</span>
                </span>
            </div>


            <p-fileUpload
                *ngIf="selectedCategory.name !== 'Link'"
                mode="basic" 
                [multiple]="multiple" 
                [accept]="fileType" 
                [maxFileSize]="maxFileInput"
                (onSelect)="onUpload($event)"
                #fileUpload
                [chooseLabel]="chooseLabel"
                [auto]="true"
                styleClass="p-button-rounded btn-modak btn-gray-primary btnUpload pi">
                <div class="btn-overlay"></div>
            </p-fileUpload>

            <div *ngIf="uploadedFiles.length">
                <div *ngFor="let file of uploadedFiles" class="fileUploadList">
                    <span class="body">{{file[0]?.name}} </span>
                    <span class="body" *ngIf="file[0]">{{ file[0].size | byteToMegaByte | number : '1.2-2'}} MB</span>
                    <button type="button" class="p-button-rounded btn-modak btn-gray-primary btnDelete"
                        (click)="removeResourceThisComponent()">
                        X
                        <div class="btn-overlay"></div>
                    </button>
                </div>
            </div>
        </div>

        <mdk-spinner [manual]="uploadingResource" [inputMessage]="!!messageUploadingResource"></mdk-spinner>
    </div>

</div>