<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <mdk-breadcrumb class="topbar-breadcrumb"></mdk-breadcrumb>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">

            <li class="switch-theme-container">
                <i class="pi pi-sun icon"></i>
                <p-inputSwitch [(ngModel)]="darkMode"  (onChange)="changeTheme($event)" class="prueba"></p-inputSwitch>
                <i class="pi pi-moon icon"></i>
            </li>

            <li class="topbar-profile">
                <button type="button" class="p-link profile-image-button" (click)="onProfileButtonClick()">
                    <img src="assets/images/modak-profile.jpeg" class="profile-image-element" alt="Profile" />
                </button>
            </li>
        </ul>
    </div>
</div>