import { NgModule } from '@angular/core';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { RippleModule } from 'primeng/ripple';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { SliderModule } from 'primeng/slider';
import { RatingModule } from 'primeng/rating';
import { FileUploadModule } from 'primeng/fileupload';
import {TabViewModule} from 'primeng/tabview';
import {ToolbarModule} from 'primeng/toolbar';
import {DialogModule} from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {SelectButtonModule} from 'primeng/selectbutton'
import {AutoCompleteModule} from 'primeng/autocomplete';
import { TagModule } from 'primeng/tag';
import {TooltipModule} from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {InputSwitchModule} from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import {AccordionModule} from 'primeng/accordion';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChipModule } from 'primeng/chip';
import { CardModule } from 'primeng/card';

@NgModule({
  imports: [
    CheckboxModule,
    ButtonModule,
    InputTextModule,
    ProgressSpinnerModule,
    InputTextModule,
    RadioButtonModule,
    TableModule,
    ToggleButtonModule,
    RippleModule,
    MultiSelectModule,
    DropdownModule,
    ProgressBarModule,
    ToastModule,
    SliderModule,
    RatingModule,
    FileUploadModule,
    TabViewModule,
    ToolbarModule,
    DialogModule,
    CalendarModule,
    ConfirmDialogModule,
    InputNumberModule,
    InputMaskModule,
    SelectButtonModule,
    AutoCompleteModule,
    TagModule,
    TooltipModule,
    InputTextareaModule,
    InputSwitchModule,
    StepsModule,
    AccordionModule,
    OverlayPanelModule,
    ChipModule,
    CardModule,
  ],
  exports:[
    CheckboxModule,
    ButtonModule,
    InputTextModule,
    ProgressSpinnerModule,
    InputTextModule,
    RadioButtonModule,
    TableModule,
    ToggleButtonModule,
    RippleModule,
    MultiSelectModule,
    DropdownModule,
    ProgressBarModule,
    ToastModule,
    SliderModule,
    RatingModule,
    FileUploadModule,
    TabViewModule,
    ToolbarModule,
    DialogModule,
    CalendarModule,
    ConfirmDialogModule,
    InputNumberModule,
    InputMaskModule,
    SelectButtonModule,
    AutoCompleteModule,
    TagModule,
    TooltipModule,
    InputTextareaModule,
    InputSwitchModule,
    StepsModule,
    AccordionModule,
    OverlayPanelModule,
    ChipModule,
    CardModule,
  ]
})
export class PrimeNgModule { }
