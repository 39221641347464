export const environmentCommon = {
    SOLID:{
        URL_DASHBOARD:'https://dashboard.solidfi.com',
    },
    firebase: {
        enabled:false,
        projectId: 'modak-web-features-flags',
        appId: '1:749226775895:web:baed7d268fd92b17431a12',
        storageBucket: 'modak-web-features-flags.appspot.com',
        apiKey: 'AIzaSyB72iuKTSQrLY5Zy3qViE6v3XyXYKX-A9E',
        authDomain: 'modak-web-features-flags.firebaseapp.com',
        messagingSenderId: '749226775895',
        usersAllowed:[
            'ferrer.diego@modak.live',
            'henry@modak.live',
            'montero.alvaro@modak.live',
            'jp.castro@modak.live',
            'lucas.vargas@modak.live'
        ],
        userApp:"tech.web+admin@modak.live",
        token:"*bHGrK*$*ZT1JlxtU1EFRQ6^1Glw25"
    },
    LOCAL_URL: "http://localhost:4500"
}