<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-profile-sidebar w-full sm:w-25rem">
    <div class="flex flex-column mx-auto md:mx-0" *ngIf="user$ | async as user">
        <span class="mb-2 font-semibold">Welcome</span>
        <span class="text-color-secondary font-medium mb-5">{{user.name}}</span>

        <ul class="list-none m-0 p-0">
            <li>
                <a class=" flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-user text-xl text-primary"></i>
                    </span>
                    <div class="ml-3 elements-container">
                        <!-- <div class="info-element">
                            <p class="text-color-secondary m-0 id-element">● {{user.id}} </p>
                            <i class="pi pi-copy text-xl text-primary copy-icon" (click)="copyToClipboard(user.id)"></i>
                        </div> -->
                        <p class="text-color-secondary m-0">● {{user.email}}</p>
                    </div>
                </a>
            </li>

            <li>
                <a class=" flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-user text-xl text-primary"></i>
                    </span>
                    <div class="ml-3 elements-container roles-">
                        <div class="text-color-secondary m-0 roles-container">----- Roles -----
                            <span *ngFor="let rol of user.roles">● {{rol}}</span>
                        </div>
                    </div>
                </a>
            </li>

            <li (click)="logout()">
                <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-power-off text-xl text-primary"></i>
                    </span>
                    <div class="ml-3 signup-container">
                        <span class="mb-2 font-semibold">Sign Out</span>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</p-sidebar>