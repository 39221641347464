<ul class="layout-menu">
    <ng-container *ngFor="let item of menuItems; let i = index;">
        <ng-container *ngIf="item.rolesAllowed?.length > 0; else noRolesBlock">
          <ng-container *hasRole="item.rolesAllowed">
            <li mdk-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
            <li *ngIf="item.separator" class="menu-separator"></li>
          </ng-container>
        </ng-container>
        <ng-template #noRolesBlock>
          <li mdk-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
          <li *ngIf="item.separator" class="menu-separator"></li>
        </ng-template>
      </ng-container>
</ul>