export const SECOND = 1000;
export const FIVE_SECONDS = SECOND * 5;
export const MINUTE = SECOND * 60;

export const CFormatDate = {
    HOUR_MINUTE_12: 'HH:mm a ',
    DATE_HOUR_MINUTE_12: 'MMMM dd, yyyy, H:mm a',
    MONTH_DATE_YEAR: 'MM-dd-yyyy',
    MONTH_3_LETTERS_DATE_YEAR: 'LLL dd, yyyy',
    MONTH_3_LETTERS_DATE_YEAR_HOUR_MINUTE_12: 'LLL dd, HH:mm a',
    DATE_OF_BIRTH: 'MM/dd/yyyy'
};