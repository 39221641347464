
export const ROLES_AVAILABLE = {
    WEB_DEVELOPER: 'WEB_DEVELOPER',
    ROLES_ADMIN: 'ROLES_ADMIN',
    TECH_DEVELOPER: 'TECH_DEVELOPER',
    OPS_REGULAR: 'OPS_REGULAR',
    OPS_EXECUTORS: 'OPS_EXECUTORS',
    OPS_BACKUPS_LEVEL_2: 'OPS_BACKUPS_LEVEL_2',
    OPS_ADMIN: 'OPS_ADMIN'
};

